<template>
<div id="faq" class="bg-blue">

  <div class="label">
    FAQ
  </div>
  <div class="wrapper">



<div class="righty" >

  <div class="titulo">Preguntas <b class="hero-title">frecuentes</b></div>
  <div class="subtitulo">
    
Navega a través de estas preguntas frecuentes para encontrar respuestas a las preguntas que se hacen comúnmente.
  </div>

  <div style="height:40px;"></div>
  <div v-for="d in datac" class="faq-container">
<div v-on:click="clickearPaso(d); d.viendo = !d.viendo" class="faq-title tit2"> {{d.pregunta}}</div>
<div :class="{'abierto':d.viendo}" class="faq-respuesta">  <div v-html="d.respuesta"></div></div>

</div>
<div style="height:40px;"></div>
<div>

</div>
<div style="height:40px;"></div>

</div>


</div>

</div>

</template>

<script>


import { mapGetters, mapActions } from "vuex";
export default {
  name: 'Bajada',
  components: {



  },
  props: {
    
  },
  data(){
    return {
    showing:false,
    examenes_showing:[],
    datac:[
    {
      viendo: false, 
      pregunta:'¿La plataforma de Clinic Go, permite agendar diferentes tipos de servicio a domicilio?', 
      respuesta:'Sí, con Clinic Go, los diversos prestadores de servicios de salud, pueden disponer de todos los servicios que quieran ofrecer en el domicilio o dónde quieran sus pacientes.'
    },
    {
      viendo: false, 
      pregunta:'¿Con Clinic Go, la experiencia de agendamiento a domicilio será mejor?', 
      respuesta:'Efectivamente, los pacientes han valorado positivamente la experiencia completa del proceso, desde el rápido y expedito agendamiento, la transparencia en los tiempos y calidad de la atención y el cobro con reembolso en el domicilio.'
    },
    {
      viendo: false, 
      pregunta:'¿Con Clinic Go, qué beneficios podrá obtener un prestador de servicios de salud?', 
      respuesta:`
      <ul>
<li> Mejoramos y monitoreamos el modelo de servicio de atención a domicilio.</li>
<li> Automatizamos el proceso completo.</li>
<li> Potenciamos la excelencia operacional.</li>
<li> Mejoramos la experiencia de sus pacientes y usuarios.</li>
<li> Fidelizamos y pontenciamos su marca con sus pacientes/usuarios.</li>
</ul>
`
    },
    {
      viendo: false, 
      pregunta:'¿Cuánto cuesta implementar Clinic Go para un prestador de salud?', 
      respuesta:'Clinic Go tiene dos modelos de negocio, tecnología especializada en atenciones al domicilio y a la medida de las necesidades y adicionalmente también ofrece la operación en terreno con personal del salud calificado. Contáctanos y podrás cotizar por nuestros servicios.'
    },
    {
      viendo: false, 
      pregunta:'¿Clinic Go ofrece directamente servicios a los usuarios?', 
      respuesta:'No, Clinic Go, es una healthtech que apoya a prestadores de salud, mejorando el acceso y la experiencia de sus pacientes en el modelo de atención domiciliaria, optimizando recursos y modernizándolo a través de la digitalización y automatización del proceso completo. Clinic Go es la única solución 100% B2B que apoya a los prestadores en potenciar su propia marca y no competimos contra ellos.'
    },


    {viendo: false, pregunta:'¿Por qué elegir ClinicGo para realizar tu examen?', respuesta:'ClinicGo cuenta con profesionales de salud especializados en atención domiciliaria, siendo reconocidos por sus pacientes en las encuestas de satisfacción. Además, contamos con diversos convenios empresas que permite tener una cobertura nacional.'},

    ],

    

    window: {
        width: 1200,
        height: 0,
      },

      

    }
  },
  methods:{
    
    clickearPaso(p){


    },
    show(ids){
      this.examenes_showing = ids;
      this.showing = true;
    },
    hide(){
      this.showing = false;
    },
  handleResize() {
        this.window.width = window.innerWidth;
        this.window.height = window.innerHeight;
    },


  },
  mounted(){

  
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  computed:{
        ...mapGetters([
      "comuna",
      "comunas",
      'examenes',
      "examenes_agregados",
      "comunas_usadas",
      "costo_servicio",
      "costo_servicio_pendiente",

      'codigo',
      'valores_codigo'
    ]),

  },
  watch:{
  
  },
}
</script>



<style scoped>

.bg-blue{
  background-color: #1485e709;
}
.label{
  margin-top: 100px;
  text-align: center;
}
.titulo{
  text-align: center;
  font-size: 28px;
  margin-top: 20px;
  margin-bottom: 6px;
  
}
.subtitulo{
  text-align: center;
  max-width: 550px;
  margin: 0 auto;
}
.mobile{
  display: none;
}

.tit1{
  font-weight: 600;
  color:#0084E4;
  font-size: 22px;

}
.tit2{

  font-size: 22px;
  color:#666666;

}

.faq-title{
  padding:30px 40px;

  text-align: left;
  cursor: pointer;
  border-radius: 5px;
  background-color: white;
  border-radius: 20px;
  font-size: 20px;
}
.faq-container{
  border-bottom: 1px solid rgb(211, 211, 211);
  margin-bottom: 20px;

  background-color: white;
  


}
.faq-title:hover{
  color:#4a29d3;
}
.faq-respuesta{
  overflow: hidden;
  max-height: 0px;
  padding: 0px 50px;
  text-align: left;
 
  transition:0.3s;

}
.abierto{
  max-height:300px;
  padding:  0px 40px 20px 40px;
}


.lefty-txt-cont{

}
.im1{
  position: absolute;
  bottom:0px;
  left:50px;
  z-index: 2;
  max-height: 600px;

}
.im2{
  position: absolute;
  bottom:00px;
  left:-80px;
  max-height: 600px;
  z-index: 0;

}
.lefty{

  position:relative;
  display: inline-block;
min-width: 600px;
vertical-align: bottom;


  


}

.wrapper{
  position: relative;
  vertical-align: top;
  text-align: left;
  max-width: 1000px;
  margin: 0 auto;



}

@media only screen and (max-width: 1230px) {
.mobile{
  display: inherit;
}
.im3{
  position: absolute;
  top:50%;
  max-width: 100%;
  left:0px;
  z-index: 0;
  height:500px;
  transform: translateY(-50%);
}
  .lefty{
    

  display: none;
}

.righty{
  position: relative !important;
  padding: 10px;

  display: inline-block;


 
  z-index: 1;

  vertical-align: top;
}
}



</style>
