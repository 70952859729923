<template>
  <div id="opiniones" class="main-bg bg-blue">
    <div class="container">
      <div class="title-column">
        <div class="testimonial-header">
          <div class="label">Opiniones Reales</div>
          <h2>¿Qué dicen nuestros clientes?</h2>
          <p>Revisa que han dicho nuestros clientes por su experiencia.</p>
          <div class="arrows">
            <button @click="moveToPrevSlide()" id="prev">❮</button>
            <button @click="moveToNextSlide()" id="next">❯</button>
          </div>
        </div>
      </div>
      <div class="carousel-column">
        <div class="testimonial-carousel">
          <div class="testimonial-slide">
            <blockquote>
              "Con Clinic Go automatizamos el proceso manual de agendar visitas a domicilio, lo cual nos trajo como beneficio optimizar nuestros recursos y mejorar la experiencia de nuestros usuarios, fidelizándolos con nuestra marca como prestador de salud visual."
            </blockquote>
            <p class="author">Karin Saez - Reley</p>
          </div>
          <div class="testimonial-slide">
            <blockquote>
              "Andinno es una startup de base tecnológica con aplicación bioclínica, y Clinic Go nos ha permitido ofrecer nuestro innovador servicio de manera rápida y con una eficiente y grata experiencia para nuestros usuarios. También nos genera eficiencia y ahorros al poder automatizar el servicio y contar con un modelo que integra tecnología, personal en terreno y traslado de muestras a nuestro laboratorio."
            </blockquote>
            <p class="author">Paula Egaña, CFO.</p>
          </div>
          <!-- Add more testimonial slides here if needed -->
        </div>
      </div>
    </div>
  </div>

  <!-- Improved Brands Logos Section -->
  <div class="bg-blue main-2">
    <div class="main-2-within">
      <div
        class="logo-container"
        v-for="(logo, index) in logos"
        :key="index"
      >
        <img :src="logo.src" :alt="logo.alt" loading="lazy" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Testimonials',
  data() {
    return {
      currentSlide: 0,
      totalSlides: 0,
      // Logos array for data-driven rendering
      logos: [
        { src: require('@/assets/cg/logos/im.png'), alt: 'IM Logo' },
        { src: require('@/assets/cg/logos/rego.png'), alt: 'Rego Logo' },
        { src: require('@/assets/cg/logos/ucchristus.png'), alt: 'UC Christus Logo' },
        { src: require('@/assets/cg/logos/davila.png'), alt: 'Dávila Logo' },
        { src: require('@/assets/cg/logos/bionet-logo.svg'), alt: 'Bionet Logo' },
        { src: require('@/assets/cg/logos/reley.png'), alt: 'Reley Logo' },
        { src: require('@/assets/cg/logos/blanco.png'), alt: 'Blanco Logo' },
        { src: require('@/assets/cg/logos/cardiopro.png'), alt: 'Cardiopro Logo' },
        { src: require('@/assets/cg/logos/sportsmd.png'), alt: 'SportsMD Logo' },
        { src: require('@/assets/cg/logos/mainque.png'), alt: 'Mainque Logo' },
      ],
    };
  },
  mounted() {
    const slides = document.querySelectorAll('.testimonial-slide');
    this.totalSlides = slides.length;
  },
  methods: {
    updateSlidePosition() {
      const slides = document.querySelectorAll('.testimonial-slide');
      for (let slide of slides) {
        slide.style.transform = 'translateX(-' + this.currentSlide * 100 + '%)';
      }
    },
    moveToNextSlide() {
      if (this.currentSlide === this.totalSlides - 1) {
        this.currentSlide = 0;
      } else {
        this.currentSlide++;
      }
      this.updateSlidePosition();
    },
    moveToPrevSlide() {
      if (this.currentSlide === 0) {
        this.currentSlide = this.totalSlides - 1;
      } else {
        this.currentSlide--;
      }
      this.updateSlidePosition();
    },
  },
};
</script>

<style scoped>
/* Existing styles for testimonials */
.label {
  margin-bottom: 20px;
}
.bg-blue {
  background-color: #1485e709;
}
.main-bg {
  border-radius: 50px 50px 0px 0px;
  min-height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.container {
  display: flex;
  margin: auto;
  max-width: 1200px;
  padding: 20px;
  box-sizing: border-box;
}
.title-column {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-right: 20px;
}
.carousel-column {
  flex-basis: 70%;
  padding-left: 20px;
}
.testimonial-header h2,
.testimonial-header p {
  margin: 0;
  padding: 0;
  text-align: left;
}
.testimonial-header h2 {
  margin-bottom: 10px;
}
.testimonial-carousel {
  display: flex;
  overflow: hidden;
  scroll-behavior: smooth;
  max-width: Calc(100vw - 100px);
}
.testimonial-slide {
  flex: 0 0 100%;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
  padding: 20px;
  box-sizing: border-box;
  background: #fff;
  transition: transform 0.5s ease;
}
.testimonial-slide blockquote {
  font-size: 1.1em;
  line-height: 1.6;
  margin: 0 0 10px 0;
}
.testimonial-slide .author {
  font-weight: bold;
  color: #333;
}
.arrows {
  display: flex;
  justify-content: center;
  gap: 10px;
  padding-top: 20px;
}
.arrows button {
  background: none;
  border: none;
  font-size: 2em;
  cursor: pointer;
  color: #333;
}
.arrows button:hover {
  color: #1485e7;
}

/* Improved Styles for Brands Logos Section */
.main-2 {
  border-top: 1px solid rgb(226, 226, 226);
  background-color: #1485e709;
  padding: 40px 20px;
}

.main-2-within {
  display: flex; /* Changed from grid to flex */
  flex-wrap: wrap; /* Allow items to wrap */
  justify-content: center; /* Center items horizontally */
  gap: 20px;
  max-width: 1200px;
  margin: 0 auto;
  align-items: center;
}

.logo-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 0 1 120px; /* Allow logos to shrink and grow */
}

.logo-container img {
  max-width: 100%;
  max-height: 80px;
  filter: grayscale(100%);
  transition: filter 0.5s ease, transform 0.3s ease;
}
.logo-container img:hover {
  filter: grayscale(0%);
  transform: scale(1.05);
}

/* Responsive adjustments */
@media only screen and (max-width: 900px) {
  .title-column {
    padding: 0px 10px;
  }
  .testimonial-header h2,
  .testimonial-header p {
    text-align: center;
  }
  .carousel-column {
    flex-basis: 100%;
    padding: 20px;
    margin: 0 auto;
    max-width: calc(100vw - 40px);
  }
  .container {
    flex-wrap: wrap;
  }
  .title-column {
    width: 100%;
    padding-bottom: 20px;
  }
  .main-2-within {
    justify-content: center; /* Ensure centering on smaller screens */
    flex: none;
  }
  .logo-container {
    flex: 0 1 100px; /* Adjust logo size for smaller screens */
  }
}

@media only screen and (max-width: 500px) {
  .main-2-within {
    justify-content: center; /* Ensure centering on very small screens */
    flex: none;
  }
  .logo-container {
    flex: 0 1 80px; /* Further adjust logo size for very small screens */
  }
  .main-2 {
    padding: 20px 10px;
  }
}
/* Add any additional styles if necessary */
</style>
