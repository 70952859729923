<template>
<footer class="footer">
  <ModalContacto ref="modalcontacto" />
    <div class="footer-container">
        <div class="footer-logo">
            <img src="@/assets/img/Logo_clinicgo_transp_white.png" alt="Company Logo">
        </div>
        <div class="footer-links">
<!--             <a href="#inicio">Inicio</a>
            <a href="#">Beneficios</a>
            <a  href="#inicio">Inicio</a> -->
          <a  href="#funcionalidades">Funcionalidades</a>
          <a href="#opiniones">Testimonios</a>
          <a href="#quienes">¿Quienes somos?</a>
          <a href="#faq">Preguntas frecuentes</a>

            <a class="btn-main" v-on:click="agendar_reu()" >Contacto</a>
        </div>
        <div class="footer-info">
            <p>Contáctanos: contacto@clinicgo.cl</p>
            <p>Teléfono: +569 9917 5313</p>
        </div>
        <div class="footer-social">


            <a href="https://cl.linkedin.com/company/clinic-go-chile">LinkedIn</a>
            <a href="https://www.instagram.com/clinicgo_chile/">Instagram</a>
        </div>
    </div>
</footer>
</template>

<script>
import ModalContacto from '@/components/ModalContacto.vue'
export default {
  name: 'HelloWorld',
  props: {
    msg: String
  },
  components: {

    ModalContacto,

  },
  data(){
    return {
      currentSlide:0,
      totalSlides:0,
    }
  },
  mounted(){
    const slides = document.querySelectorAll('.testimonial-slide');
    this.totalSlides = slides.length

},
  methods:{
    agendar_reu(){
      this.$refs.modalcontacto.show()
    },
      updateSlidePosition() {
        const slides = document.querySelectorAll('.testimonial-slide');
        console.log(slides)
        for (let slide of slides) {
          slide.style.transform = 'translateX(-' + this.currentSlide * 100 + '%)';
        }
      },

      moveToNextSlide() {
        if (this.currentSlide === this.totalSlides - 1) {
          this.currentSlide = 0;
        } else {
          this.currentSlide++;
        }
        this.updateSlidePosition();
      },

    moveToPrevSlide() {
      if (this.currentSlide === 0) {
        this.currentSlide = this.totalSlides - 1;
      } else {
        this.currentSlide--;
      }
      this.updateSlidePosition();
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.btn-main {
  display: inline-block;
  margin-left: 20px;
  color: white !important;
  background: linear-gradient(90deg, #1486e7 0%, #5a4aff 47.92%, #69bfff 100%);
}
.footer {
    background-color: #333;
    color: white;
    text-align: center;
    padding: 20px 0;
}

.footer-container {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    max-width: 1200px;
    margin: 0 auto;
}

.footer-logo img {
    width: 100px;
    height: auto;
}

.footer-links a, .footer-social a {
    display: block;
    color: white;
    text-decoration: none;
    margin: 5px 0;
    font-size: 14px;
}

.footer-info p {
    margin: 5px 0;
}

/* Responsive design */
@media (max-width: 600px) {
    .footer-container {
        flex-direction: column;
    }
}
</style>
